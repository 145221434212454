//import Slider4 from "./js/slider4.js";
class Slider7 {
    constructor() {
        this.dir = -1;
        this.timeout = 6000;
        this.div=null;
        this.div2=null;
        this.wind=null;
        this.count = 3;
        this.w = null;
        // debugger;
        this.run();
    }
    slide() {

        let slides = this.div.querySelector('.js-slider-slides');
        slides.style.position='absolute';
        
        let count = slides.childElementCount;

        let offset = Math.floor(slides.offsetLeft / this.w) * this.w;

        offset = offset + this.dir * this.w;
        if (Math.abs(offset) > ((count - 3) * (this.w)) || offset > 0) {
            offset = 0;
        }
        slides.style.left = offset + 'px';

        clearTimeout(this.pid);
        this.pid = setTimeout(this.slide.bind(this), this.timeout);
    }

    click(ev) {
        let target = ev.target;
        let x = ev.offsetX;

        if ((x < target.offsetWidth / 2 && this.dir < 0) || (x > target.offsetWidth / 2 && this.dir > 0))
            this.dir = -this.dir;
        this.slide();
    }

    run() {
        this.div = document.querySelector('.js-slider');
        if(!this.div) return;
        this.div2 = this.div.querySelector('.js-slider-slides');
        let active = this.div.querySelector('.slide.active');
        this.w=active.clientWidth; //save calculated CSS width
        this.div2.style.position='absolute';

        
        this.pid = setTimeout(this.slide.bind(this), this.timeout);
        this.wind = this.div.querySelector('.js-slider-window');
        // this.wind.addEventListener('click', this.click.bind(this));
        this.wind.querySelector('.arrow-right').addEventListener('click',()=>{
            this.slide();
        });

        this.wind.querySelector('.arrow-left').addEventListener('click',()=>{
            let dir =this.dir;
            this.dir=1;
            this.slide();
            this.dir=dir;
        })
        // this.resize(false);
        this.div.querySelectorAll('.js-slider-slides .slide').forEach(s => {
            s.style.width = this.w + 'px'; //overwrite css percentage width
            s.style.display='block';
        });
        
        // window.addEventListener('resize', this.resize.bind(this,false))
    }

    /*resize(immediate = true) {

        this.count = Math.floor(this.wind.clientWidth / 230);

        this.w = this.wind.clientWidth / this.count;
        this.div.querySelectorAll('.js-slider-slides .slide').forEach(s => {
            s.style.width = this.w + 'px';
        })
        if (immediate)
            this.slide();
        else
            this.pid = setTimeout(this.slide.bind(this), this.timeout);
    }*/
}

document.addEventListener('DOMContentLoaded',function(){
    console.log("MS: init");
    let slider = new Slider7;
    window.minslider = slider;
});

// slider.run();